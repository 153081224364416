:root {
  --vz-vertical-menu-bg: #fff;
  --vz-vertical-menu-item-color: #6d7080;
  --vz-vertical-menu-item-hover-color: #405189;
  --vz-vertical-menu-item-active-color: #405189;
  --vz-vertical-menu-sub-item-color: #7c7f90;
  --vz-vertical-menu-sub-item-hover-color: #405189;
  --vz-vertical-menu-sub-item-active-color: #405189;
  --vz-vertical-menu-title-color: #919da9;
  --vz-vertical-menu-bg-dark: #3a6a23;
  --vz-vertical-menu-item-color-dark: #fff;
  --vz-vertical-menu-item-hover-color-dark: #8bc34a;
  --vz-vertical-menu-item-active-color-dark: #fff;
  --vz-vertical-menu-sub-item-color-dark: #fff;
  --vz-vertical-menu-sub-item-hover-color-dark: #fff;
  --vz-vertical-menu-sub-item-active-color-dark: #fff;
  --vz-vertical-menu-title-color-dark: #fff;
  --vz-header-bg: #fff;
  --vz-header-item-color: #e9ecef;
  --vz-header-bg-dark: #405189;
  --vz-header-item-color-dark: #b0c4d9;
  --vz-topbar-search-bg: #f3f3f9;
  --vz-topbar-user-bg: #f8faf4;
  --vz-topbar-user-bg-dark: #52639c;
  --vz-footer-bg: #fff;
  --vz-footer-color: #98a6ad;
  --vz-topnav-bg: #fff;
  --vz-topnav-item-color: #6d7080;
  --vz-topnav-item-color-active: #405189;
  --vz-twocolumn-menu-iconview-bg: #fff;
  --vz-twocolumn-menu-bg: #fff;
  --vz-twocolumn-menu-iconview-bg-dark: var(--vz-vertical-menu-bg-dark);
  --vz-twocolumn-menu-bg-dark: #1b5e20;
  --vz-twocolumn-menu-item-color-dark: var(--vz-vertical-menu-item-color-dark);
  --vz-twocolumn-menu-item-active-color-dark: #fff;
  --vz-twocolumn-menu-item-active-bg-dark: rgba(255, 255, 255, 0.15);
  --vz-boxed-body-bg: #e5e5f2;
  --vz-heading-color: #495057;
  --vz-link-color: #405189;
  --vz-link-hover-color: #405189;
  --vz-border-color: #e9ebec;
  --vz-card-bg-custom: #fff;
  --vz-card-logo-dark: block;
  --vz-card-logo-light: none;
  --vz-list-group-hover-bg: #f3f6f9;
  --vz-input-bg: #fff;
  --vz-input-border: #d0dace;
  --vz-input-focus-border: #a8c4a0;
  --vz-input-disabled-bg: #eff2f7;
  --vz-input-group-addon-bg: #eff2f7;
  --vz-input-check-border: var(--vz-input-border);
}

:root {
  --vz-blue: #3577f1;
  --vz-indigo: #405189;
  --vz-purple: #6559cc;
  --vz-pink: #f672a7;
  --vz-red: #f06548;
  --vz-orange: #f1963b;
  --vz-yellow: #f7b84b;
  --vz-green: #0ab39c;
  --vz-teal: #02a8b5;
  --vz-cyan: #299cdb;
  --vz-white: #fff;
  --vz-gray: #878a99;
  --vz-gray-dark: #343a40;
  --vz-gray-100: #f3f6f9;
  --vz-gray-200: #eff2f7;
  --vz-gray-300: #e9ebec;
  --vz-gray-400: #ced4da;
  --vz-gray-500: #adb5bd;
  --vz-gray-600: #878a99;
  --vz-gray-700: #495057;
  --vz-gray-800: #343a40;
  --vz-gray-900: #212529;
  --vz-primary: #405189;
  --vz-secondary: #3577f1;
  --vz-success: #0ab39c;
  --vz-info: #299cdb;
  --vz-warning: #f7b84b;
  --vz-danger: #f06548;
  --vz-danger-bg-subtle: #fde8e4;
  --vz-primary-bg-subtle: #e2e5ed;
  --vz-secondary-bg-subtle: #e1ebfd;
  --vz-success-bg-subtle: #d2e7c5;
  --vz-info-bg-subtle: #dff0fa;
  --vz-warning-bg-subtle: #fef4e4;
  --vz-light-bg-subtle: #f9fbfc;
  --vz-dark-bg-subtle: #e9ebec;
  --vz-light: #f3f6f9;
  --vz-dark: #212529;
  --vz-primary-rgb: 51, 105, 30;
  --vz-secondary-rgb: 53,119,241;
  --vz-success-rgb: 104, 159, 56;
  --vz-info-rgb: 41,156,219;
  --vz-warning-rgb: 247,184,75;
  --vz-danger-rgb: 240,101,72;
  --vz-light-rgb: 248, 250, 244;
  --vz-dark-rgb: 33,37,41;
  --vz-white-rgb: 255,255,255;
  --vz-black-rgb: 0,0,0;
  --vz-body-color-rgb: 33,37,41;
  --vz-body-bg-rgb: 243,243,249;
  --vz-font-sans-serif: "Poppins",sans-serif;
  --vz-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --vz-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --vz-body-font-family: var(--vz-font-sans-serif);
  --vz-body-font-size: 0.8125rem;
  --vz-body-font-weight: 400;
  --vz-body-line-height: 1.5;
  --vz-body-color: #212529;
  --vz-body-bg: #f3f9f3;
  --vz-border-width: 1px;
  --vz-border-style: solid;
  --vz-border-color-translucent: rgba(0, 0, 0, 0.175);
  --vz-border-radius: 0.25rem;
  --vz-border-radius-sm: 0.2rem;
  --vz-border-radius-lg: 0.3rem;
  --vz-border-radius-xl: 1rem;
  --vz-border-radius-2xl: 2rem;
  --vz-border-radius-pill: 50rem;
  --vz-link-color: var(--vz-link-color);
  --vz-link-hover-color: var(--vz-link-hover-color);
  --vz-code-color: #f672a7;
  --vz-highlight-bg: #fcf8e3;
}

html {
  /* Tooltip text */
  /* Tooltip arrow */
  /* Show the tooltip text when you mouse over the tooltip container */
}
html .btn-success {
  --vz-btn-color: #fff;
  --vz-btn-bg: #689f38;
  --vz-btn-border-color: #689f38;
  --vz-btn-hover-color: #fff;
  --vz-btn-hover-bg: #558b2f;
  --vz-btn-hover-border-color: #558b2f;
  --vz-btn-focus-shadow-rgb: 47,190,171;
  --vz-btn-active-color: #fff;
  --vz-btn-active-bg: #558b2f;
  --vz-btn-active-border-color: #558b2f;
  --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --vz-btn-disabled-color: #fff;
  --vz-btn-disabled-bg: #689f38;
  --vz-btn-disabled-border-color: #689f38;
}
html .auth-one-bg .bg-overlay {
  background: #c5e1a5;
}
html .form-check-input:checked {
  background-color: #689f38;
  border-color: #689f38;
}
html .btn-light {
  --vz-btn-color: #000;
  --vz-btn-bg: #f3f9f3;
  --vz-btn-border-color: #f3f9f3;
  --vz-btn-hover-color: #000;
  --vz-btn-hover-bg: #cfd4d0;
  --vz-btn-hover-border-color: #cfd4d0;
  --vz-btn-focus-shadow-rgb: 207,209,212;
  --vz-btn-active-color: #000;
  --vz-btn-active-bg: #c2c7c2;
  --vz-btn-active-border-color: #c2c7c2;
  --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --vz-btn-disabled-color: #000;
  --vz-btn-disabled-bg: #f3f9f4;
  --vz-btn-disabled-border-color: #f3f9f4;
}
html .btn-ghost-primary {
  --vz-btn-color: #689f38;
  --vz-btn-bg: transparent;
  --vz-btn-border-color: transparent;
  --vz-btn-hover-color: #ffffff;
  --vz-btn-hover-bg: rgba(64, 81, 137, 0.1);
  --vz-btn-hover-border-color: transparent;
  --vz-btn-focus-shadow-rgb: 64, 81, 137;
  --vz-btn-active-color: #689f38;
  --vz-btn-active-bg: rgba(243, 249, 243, 1);
  --vz-btn-active-border-color: transparent;
}
html .pagination {
  --vz-pagination-padding-x: 0.75rem;
  --vz-pagination-padding-y: 0.375rem;
  --vz-pagination-font-size: 0.8125rem;
  --vz-pagination-color: var(--vz-link-color);
  --vz-pagination-bg: var(--vz-card-bg-custom);
  --vz-pagination-border-width: 1px;
  --vz-pagination-border-color: var(--vz-border-color);
  --vz-pagination-border-radius: 0.25rem;
  --vz-pagination-hover-color: var(--vz-link-hover-color);
  --vz-pagination-hover-bg: #f3f9f3;
  --vz-pagination-hover-border-color: var(--vz-border-color);
  --vz-pagination-focus-color: var(--vz-link-hover-color);
  --vz-pagination-focus-bg: #f3f9f3;
  --vz-pagination-focus-box-shadow: 0 0 0 0 rgba(64, 81, 137, 0.25);
  --vz-pagination-active-color: #fff;
  --vz-pagination-active-bg: #689f38;
  --vz-pagination-active-border-color: #689f38;
  --vz-pagination-disabled-color: #878a99;
  --vz-pagination-disabled-bg: var(--vz-card-bg-custom);
  --vz-pagination-disabled-border-color: var(--vz-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}
html .profile-wid-bg::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.9;
  background: #405189;
  background: linear-gradient(to top, #1e3217, #33691e);
}
html .profile-project-card.profile-project-success {
  border-left-color: #689f38;
  transition: 0.3s all;
}
html .profile-project-card.profile-project-success:hover {
  border-color: #689f38;
}
html .mini-btn {
  padding: 3px 8px;
  margin-top: 19px;
  font-size: 12px;
}
html .modal.fade.show {
  background-color: rgba(0, 0, 0, 0.4784313725);
}
html .sidebar-background {
  background-image: url("../img/default-background.webp");
}
html .editbox {
  cursor: pointer;
}
html .folder-card {
  cursor: pointer;
}
html .folder-card .bg-light {
  cursor: pointer;
  border: 1px solid #f8faf4;
}
html .folder-card.active .bg-light {
  border: 1px solid #689f38;
}
html .folder-card:hover .bg-light {
  border: 1px solid #689f38;
}
html .simplebar-mask {
  z-index: unset;
}
html .link-success {
  color: #689f38 !important;
}
html .link-success:hover {
  color: #497825 !important;
}
html .file-manager-menu li span.active, html .file-manager-menu li span:hover, html .file-manager-menu li span[aria-expanded=true] {
  color: #689f38;
}
html .file-manager-menu li a.active, html .file-manager-menu li a.active span, html .file-manager-menu li a:hover, html .file-manager-menu li a[aria-expanded=true] {
  color: #689f38;
}
html .form-control-edit {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  padding: 0;
  width: unset;
  display: inline;
  background-color: transparent;
}
html .form-control-edit:focus {
  background-color: transparent;
}
html ul li i {
  opacity: 0;
  transition: 0.3s all;
}
html ul li .active:hover i {
  opacity: 1;
}
html .notification-dropdown {
  position: absolute;
  inset: 0 0 auto auto;
  margin: 0;
  transform: translate(0px, 75px);
}
html .show .notification-btn {
  color: var(--vz-btn-hover-color);
  background-color: var(--vz-btn-hover-bg);
  border-color: var(--vz-btn-hover-border-color);
}
html .notification-btn {
  position: relative;
}
html .step-icon:before {
  display: none;
}
html .vertical-navs-step .nav .nav-link.done {
  border-color: #689f38;
}
html .vertical-navs-step .nav .nav-link.active {
  border-color: #3e6a24;
}
html .vertical-navs-step .nav .nav-link.active .step-icon, html .vertical-navs-step .nav .nav-link.done .step-icon {
  color: #689f38;
}
html .saison-calendar .fc .fc-view {
  position: relative;
}
html .saison-calendar .fc .fc-scroller-liquid-absolute {
  position: relative;
}
html .title-red-banner {
  background-color: #ff0000;
  color: #fff;
}
html .title-orangered-banner {
  background-color: #ff9c00;
  color: #000;
}
html .title-orange-banner {
  background-color: #f3bf99;
  color: #000;
}
html .title-green-banner {
  background-color: yellow;
  color: #000;
}
html .form-check-success .form-check-input:checked {
  background-color: #689f38;
  border-color: #689f38;
}
html .fc table .fc-day {
  position: relative;
  overflow: hidden;
}
html .fc table .fc-day:before {
  background-color: transparent;
  content: "";
  height: 48px;
  left: -24px;
  position: absolute;
  top: 9px;
  transform: rotate(-45deg);
  width: 48px;
}
html .fc table .fc-day:after {
  background-color: transparent;
  content: "";
  height: 48px;
  left: 6px;
  position: absolute;
  top: 9px;
  transform: rotate(45deg);
  width: 48px;
}
html .fc .fc-daygrid-day-number {
  border-radius: 0;
}
html #saison-calendar:not(.noShowSaison) .fc .firstSeasonStart .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeasonEnd .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeason .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonStart .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonEnd .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeason .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonEnd .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonStart .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeason .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonEnd .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonStart .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeason .fc-daygrid-day-number {
  align-items: end;
  background-color: transparent !important;
  color: #fff;
  justify-content: end;
}
html #saison-calendar:not(.noShowSaison) .fc .firstSeasonStart .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .firstSeasonEnd .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .firstSeason .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonStart .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonEnd .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .secondSeason .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonEnd .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonStart .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .thirdSeason .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonEnd .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonStart .fc-daygrid-day-top, html #saison-calendar:not(.noShowSaison) .fc .fourthSeason .fc-daygrid-day-top {
  justify-content: end;
}
html #saison-calendar:not(.noShowSaison) .fc .firstSeasonStart.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeasonEnd.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeason.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonStart.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonEnd.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeason.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonEnd.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonStart.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeason.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonEnd.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonStart.veryLowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeason.veryLowDensity .fc-daygrid-day-number {
  color: #5ae781;
}
html #saison-calendar:not(.noShowSaison) .fc .firstSeasonStart.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeasonEnd.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeason.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonStart.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonEnd.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeason.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonEnd.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonStart.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeason.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonEnd.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonStart.lowDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeason.lowDensity .fc-daygrid-day-number {
  color: #e9ea70;
}
html #saison-calendar:not(.noShowSaison) .fc .firstSeasonStart.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeasonEnd.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeason.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonStart.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonEnd.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeason.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonEnd.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonStart.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeason.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonEnd.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonStart.midDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeason.midDensity .fc-daygrid-day-number {
  color: #eac670;
}
html #saison-calendar:not(.noShowSaison) .fc .firstSeasonStart.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeasonEnd.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeason.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonStart.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonEnd.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeason.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonEnd.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonStart.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeason.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonEnd.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonStart.highDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeason.highDensity .fc-daygrid-day-number {
  color: #ea8d70;
}
html #saison-calendar:not(.noShowSaison) .fc .firstSeasonStart.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeasonEnd.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .firstSeason.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonStart.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeasonEnd.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .secondSeason.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonEnd.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonStart.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .thirdSeason.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonEnd.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonStart.veryHighDensity .fc-daygrid-day-number, html #saison-calendar:not(.noShowSaison) .fc .fourthSeason.veryHighDensity .fc-daygrid-day-number {
  color: #f74040;
}
html #saison-calendar:not(.noShowSaison) .fc {
  --fc-season1-color: #567EDB;
  --fc-season2-color: #2F62D7;
  --fc-season3-color: #0937A2;
  --fc-season4-color: #062A7E;
}
html #saison-calendar:not(.noShowSaison) .fc .fc-daygrid-day-top {
  justify-content: center;
}
html #saison-calendar:not(.noShowSaison) .fc .firstSeasonStart:after {
  background-color: var(--fc-season1-color) !important;
  border-left: 1px solid white;
}
html #saison-calendar:not(.noShowSaison) .fc .firstSeasonEnd:before {
  background-color: var(--fc-season1-color) !important;
}
html #saison-calendar:not(.noShowSaison) .fc .firstSeason {
  background-color: var(--fc-season1-color) !important;
}
html #saison-calendar:not(.noShowSaison) .fc .secondSeasonStart:after {
  background-color: var(--fc-season2-color) !important;
  border-left: 1px solid white;
}
html #saison-calendar:not(.noShowSaison) .fc .secondSeasonEnd:before {
  background-color: var(--fc-season2-color) !important;
}
html #saison-calendar:not(.noShowSaison) .fc .secondSeason {
  background-color: var(--fc-season2-color) !important;
}
html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonStart:after {
  background-color: var(--fc-season3-color) !important;
  border-left: 1px solid white;
}
html #saison-calendar:not(.noShowSaison) .fc .thirdSeasonEnd:before {
  background-color: var(--fc-season3-color) !important;
}
html #saison-calendar:not(.noShowSaison) .fc .thirdSeason {
  background-color: var(--fc-season3-color) !important;
}
html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonStart:after {
  background-color: var(--fc-season4-color) !important;
  border-left: 1px solid white;
}
html #saison-calendar:not(.noShowSaison) .fc .fourthSeasonEnd:before {
  background-color: var(--fc-season4-color) !important;
}
html #saison-calendar:not(.noShowSaison) .fc .fourthSeason {
  background-color: var(--fc-season4-color) !important;
}
html .firstSeasonStart:after {
  background-color: #1a237e !important;
}
html .firstSeasonEnd:before {
  background-color: #1a237e !important;
}
html .firstSeason {
  background-color: #1a237e !important;
}
html .secondSeasonStart:after {
  background-color: #1976d2 !important;
}
html .secondSeasonEnd:before {
  background-color: #1976d2 !important;
}
html .secondSeason {
  background-color: #1976d2 !important;
}
html .thirdSeasonStart:after {
  background-color: #64b5f6 !important;
}
html .thirdSeasonEnd:before {
  background-color: #64b5f6 !important;
}
html .thirdSeason {
  background-color: #64b5f6 !important;
}
html .fourthSeasonStart:after {
  background-color: #e1f5fe !important;
}
html .fourthSeasonEnd:before {
  background-color: #e1f5fe !important;
}
html .fourthSeason {
  background-color: #e1f5fe !important;
}
html .veryLowDensity .fc-daygrid-day-number {
  background-color: #5ae781;
}
html .lowDensity .fc-daygrid-day-number {
  background-color: #e9ea70;
}
html .midDensity .fc-daygrid-day-number {
  background-color: #eac670;
}
html .highDensity .fc-daygrid-day-number {
  background-color: #ea8d70;
}
html .veryHighDensity .fc-daygrid-day-number {
  background-color: #f74040;
}
html .noShowDensity .fc-daygrid-day-number {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
html .noShowSaison .fc table td.fc-day {
  background-color: transparent !important;
}
html .noShowSaison .fc table td.fc-day:before {
  display: none !important;
}
html .noShowSaison .fc table td.fc-day:after {
  display: none !important;
}
html .fc .fc-daygrid-day-top {
  justify-content: center;
}
html .dropdown-container-absolute {
  position: absolute;
  z-index: 1055;
  right: 0;
  margin: 1rem;
}
html .olap-card div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: block;
  width: 100%;
}
html .olap-card div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  width: 100%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
html .olap-card .btn.btn-olap {
  padding: 0 15px;
  height: 27px;
  display: flex;
  align-items: center;
}
html .olap-card .btn.btn-olap.btn-toggled {
  background-color: transparent;
  border: 1px solid var(--vz-btn-border-color);
  color: var(--vz-btn-border-color);
}
html .olap-card .olap-row {
  margin: 0;
}
html .olap-table {
  max-width: 100%;
  overflow-x: scroll;
  position: relative;
}
html .olap-table .season-view td {
  min-width: 500px;
}
html .olap-table table {
  width: 100%;
}
html .olap-table table thead th {
  position: relative;
  background-color: #fafafa;
}
html .olap-table table thead th.hovered {
  background-color: #f3f9f3;
}
html .olap-table table thead th:first-child {
  left: -1px;
  z-index: 1;
}
html .olap-table table .dtr-control {
  left: -1px;
  background-color: #fcfcfc;
  width: 250px;
  min-width: 250px;
  z-index: 1050;
}
html .olap-table table .dtr-control .rowTitle {
  position: relative;
}
html .olap-table table .dtr-control .rowTitle .rowTutorial {
  position: absolute;
  top: -10px;
  right: -10px;
}
html .olap-table table .dtr-control .rowTitle .rowTutorial .btn-olap-success {
  padding: 0 !important;
  background-color: transparent;
  box-shadow: none !important;
  border: none;
  height: 14px;
  width: unset;
  font-size: unset;
  line-height: unset;
}
html .olap-table table .dtr-control .rowTitle .rowTutorial .btn-olap-success svg {
  font-size: 14px !important;
  vertical-align: unset;
  color: #777;
}
html .olap-table table td, html .olap-table table th {
  min-width: 120px;
  padding: 15px;
}
html .olap-table table .olap-input {
  height: 21px;
  width: 100%;
  margin: 0 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: text;
}
html .olap-table table .olap-input input {
  width: 100%;
  border-bottom: 1px solid #ccc;
  text-align: left !important;
}
html .olap-table table .btn-olap-modal {
  font-size: 12px;
  padding: 1px !important;
  width: 100%;
}
html .olap-table table .table-month span {
  position: sticky;
  left: 265px;
}
html .olap-table .olap-table-hover tbody tr:hover td:first-child {
  background-color: #f3f9f3;
}
html .olap-table .olap-table-hover tbody tr:hover td:hover {
  background-color: #f3f9f3;
}
html .olap-table .season-view .olap-input {
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}
html .olap-table .season-view .olap-input input {
  width: 100%;
}
html .olap-table .olap-open-button .icon {
  transition: 0.3s all;
  display: inline-block;
  cursor: pointer;
}
html .olap-table .olap-open-button.active .icon {
  transform: rotate(180deg);
}
html .profile-project-card.camp-profile-project-card {
  border: none;
}
html .profile-project-card.camp-profile-project-card .card-body {
  border: 1px solid #e9ebec;
  padding-top: 40px !important;
}
html .card-header.camp-profile-card-header {
  background-color: #689f38;
  border-bottom: 1px solid #e9ebec;
}
html .card-header.camp-profile-card-header img {
  display: block;
  z-index: 1;
  position: relative;
  max-width: 70px;
  margin: 0 auto -49px;
  border-radius: 50%;
  border: 2px solid #e9ebec;
}
html .card-header.camp-profile-card-header .organisationavatar {
  z-index: 1;
  position: relative;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  margin: 0 auto -49px;
  border-radius: 50%;
  border: 2px solid #e9ebec;
  background-color: #ffffff;
  color: #689f38;
  font-size: 32px;
}
html .li-unstyled {
  padding-left: 10px !important;
}
html .li-unstyled:before {
  display: none;
}
html .li-unstyled2 {
  padding-left: 20px !important;
}
html .li-unstyled-30 {
  padding-left: 30px !important;
}
html .li-unstyled-60 {
  padding-left: 60px !important;
}
html .olap-sidebar-button .icon {
  transition: 0.3s all;
  display: inline-block;
  transform: rotate(-90deg);
}
html .olap-sidebar-button.active .icon {
  transform: rotate(0deg);
}
html .droparea {
  border: 1px dashed #e9ebec;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  min-height: 50px;
}
html .droparea:hover {
  border-color: #689f38;
}
html .droparea .card .card-body {
  padding: 12px;
}
html .stroke-success path {
  stroke: #689f38;
}
html .height-unset {
  height: unset !important;
  max-height: unset !important;
}
html .olap-description-text {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
html .olap-table .month-view .col-2, html .olap-table .month-view .col-3, html .olap-table .month-view .col-4, html .olap-table .month-view .col-5, html .olap-table .month-view .col-6, html .olap-table .month-view .col-7, html .olap-table .month-view .col-8, html .olap-table .month-view .col-9, html .olap-table .month-view .col-10, html .olap-table .month-view .col-11, html .olap-table .month-view .col-12 {
  width: 100%;
}
html .olap-table .ql-editor {
  height: 375px;
}
html .draggable {
  cursor: move;
}
html .text-danger-hover {
  color: #878a99 !important;
}
html .text-danger-hover:hover {
  color: #f06548 !important;
}
html .text-success-hover {
  color: #878a99 !important;
}
html .text-success-hover:hover {
  color: #689f38 !important;
}
html .pointer-event-none {
  pointer-events: none;
}
html .white-space-normal {
  white-space: normal;
}
html .weight-300 {
  font-weight: 300;
}
html .position-sticky-90 {
  position: sticky;
  top: 90px;
}
html .text-justify {
  text-align: justify;
}
html .tooltipCp {
  position: relative;
  display: inline-block;
  margin-left: 4px;
  font-size: 14px;
  top: -6px;
  color: #ccc;
}
html .tooltipCp .tooltiptextCp {
  visibility: hidden;
  max-width: 500px;
  width: 500px;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -250px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}
html .tooltipCp .tooltiptextCp::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
html .tooltipCp:hover .tooltiptextCp {
  visibility: visible;
  opacity: 1;
}
html .dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
html .olap-media-preview {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-top: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
}
html .olap-media-preview .olap-media-inner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}
html .olap-media-preview .olap-media-inner img {
  display: block;
  width: auto;
  height: 100%;
}
html .olap-media-preview .btn-remove {
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0;
  color: #f00;
  background: transparent;
  font-size: 20px;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
html .olap-media-preview .btn-remove svg {
  vertical-align: top;
}
html .olap-media-preview .btn-remove:hover {
  opacity: 1;
}
html .cp-tooltip {
  position: relative;
  display: inline-block;
  color: #ccc;
}
html .cp-tooltip .tooltiptext {
  visibility: hidden;
  max-width: 120px;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
html .cp-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
html .new-object-toast {
  position: fixed;
  bottom: 40px;
  right: 80px;
  background-color: #689f38;
  box-shadow: unset;
  z-index: 101;
  cursor: unset;
}
html .new-object-toast .btn {
  color: #fff;
  opacity: 1;
  position: relative;
  display: inline-block;
  z-index: 111;
}
@media (min-width: 1200px) {
  html .modal-xxl {
    --vz-modal-width: 1400px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  html .modal-xxl {
    --vz-modal-width: 1200px;
  }
}
html .olap-table table .placeholder-background {
  background-color: #eeeeee;
}

table tr th, table tr td {
  vertical-align: top;
}
table tr th.no-min-width, table tr td.no-min-width {
  min-width: 0 !important;
}
table tr th .table-cell-label, table tr td .table-cell-label {
  color: var(--vz-gray-600);
  cursor: default;
  font-size: 12px;
}
table tr th .delete-edit-geo-coordinates-item, table tr td .delete-edit-geo-coordinates-item {
  vertical-align: top;
  cursor: pointer;
  color: var(--vz-gray-400);
}
table tr th .delete-edit-geo-coordinates-item:hover, table tr td .delete-edit-geo-coordinates-item:hover {
  color: var(--vz-danger);
}
table .no-hover-effect tr, table .no-hover-effect td {
  background-color: transparent !important;
}

.dropdown-menu * {
  cursor: pointer;
}

.collapse-able-row {
  display: none;
}
.collapse-able-row td {
  padding-top: 0 !important;
}
.collapse-able-row.open {
  display: table-row;
}

.transparent-border {
  border-color: transparent !important;
}

.leaflet-container {
  cursor: pointer !important;
}

html .bg-light {
  background-color: #eee !important;
}

